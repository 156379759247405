document.addEventListener('turbo:load', function (event) {
  // document
  //   .querySelectorAll(".arrow")
  //   .forEach((button) => button.addEventListener("click", toggle));

  function toggle () {
    const btn = this
    btn.classList.add('animation')
    btn.classList.toggle('active')
    const newElem = btn.cloneNode(true)
    btn.parentNode.replaceChild(newElem, btn)
    newElem.addEventListener('click', toggle)
  }
})
