/* eslint no-console:0 */
// To reference this file, add <%= vite_javascript_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// Shared imports between multiple entrypoints
import '../shared/shared_imports.js';

// Stimulus controllers
import { Application } from "stimulus";
const application = Application.start();

import NewsletterSubsController from '../controllers/newsletter_subs_controller.js'
application.register('newsletter-subs', NewsletterSubsController)

import MobileMenuController from '../controllers/mobile_menu_controller.js'
application.register('mobile-menu', MobileMenuController)

import FaqController from '../controllers/faq_controller.js'
application.register('faq', FaqController)

import LightboxController from '../controllers/lightbox_controller.js'
application.register('lightbox', LightboxController)

import TopicsController from '../controllers/topics_controller.js'
application.register('topics', TopicsController)

import CarouselController from '../controllers/carousel_controller.js'
application.register('carousel', CarouselController)

import ContactUsController from '../controllers/contact_us_controller.js'
application.register('contact-us', ContactUsController)

import BackLinkController from '../controllers/back_link_controller.js'
application.register('back-link', BackLinkController)

import TooltipController from '../controllers/tooltip_controller.js'
application.register('tooltip', TooltipController)

import AppearController from '../controllers/appear_controller.js'
application.register('appear', AppearController)

import CollapseController from '../controllers/collapse_controller.js'
application.register('collapse', CollapseController)

import DailyActionInfoController from '../controllers/daily_action_info_controller.js'
application.register('daily-action-info', DailyActionInfoController)

import KeyFeaturesController from '../controllers/key_features_controller.js'
application.register('key-features', KeyFeaturesController)

import LandingCarouselController from '../controllers/landing_carousel_controller.js'
application.register('landing-carousel', LandingCarouselController)

import ImageTranslationController from '../controllers/image_translation_controller.js'
application.register('image-translation', ImageTranslationController)

import '../components/dropdown-arrow.js'

// Required for micromodal.js, Jumpstart Pro & other Functionality
// const components = require.context('src', true)
// components.keys().forEach(components)

// manuall import forms.js, lazysrc.js, micromodal.js, material_forms.js, stripe_checkout.js
import '../src/forms.js';
import '../src/lazysrc.js';
import '../src/micromodal.js';
import '../src/material_forms.js';
import '../src/stripe_checkout.js';
import '../src/weglot.js';

// ADD YOUR JAVASCRIPT HERE

// Start Rails UJS
// Rails.start()
